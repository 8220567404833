import React, { useEffect, useState, useRef } from 'react';
import { navigate } from 'gatsby';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import HeaderBar from 'components/header-bar';
import AdditionItem from 'components/addition-item';

import { useAPI } from 'utils/api';
import store, { actions, getters } from 'redux/store';

const Content = styled.div`
  background-color: #f2f2f2;
  padding: 70px 16px 16px;
  min-height: calc(100% - 55px);
  box-sizing: border-box;

  .result-title {
    font-family: Noto Sans TC;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
    margin-bottom: 8px;
  }

  .result-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 8px;
  }

  .no-result-information {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    box-sizing: border-box;
    width: 80%;
  }
`;

let currentPage = 1;
let totalPage = 0;
const perPage = 12;
let arrProducts = [];

const SearchProductPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  return (
    <>
      <ContentLoader
        viewBox={`0 0 ${358 * ratio} 1000`}
        height={1000}
        width={358 * ratio}
        backgroundColor="#FFFFFF"
        foregroundColor="#dedede"
      >
        {[...Array(5)].map((i, index) => {
          return (
            <>
              {[...Array(3)].map((j, subIndex) => {
                return (
                  <>
                    <rect
                      x={subIndex * 127 * ratio}
                      y={20 + 220 * index}
                      rx="8"
                      ry="8"
                      width={105 * ratio}
                      height="140"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={170 + 220 * index}
                      rx="0"
                      ry="0"
                      width={30 * ratio}
                      height="16"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={190 + 220 * index}
                      rx="0"
                      ry="0"
                      width={105 * ratio}
                      height="16"
                    />
                    <rect
                      x={subIndex * 127 * ratio}
                      y={210 + 220 * index}
                      rx="0"
                      ry="0"
                      width={80 * ratio}
                      height="16"
                    />
                  </>
                );
              })}
            </>
          );
        })}
      </ContentLoader>
    </>
  );
};

const Addition = ({ location }) => {
  const api = useAPI();
  const resultList = useRef();

  const additionItems = useSelector((state) => getters.getAdditionItems(state));

  const [searchWord, setSearchWord] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [showPage, setShowPage] = useState(false);

  const pageName = '搜尋結果頁';

  const fetchAdditionItemList = () => {
    store.dispatch(actions.setAdditionItems([]));
    const params = {
      shippingModel: 1,
    };
    api
      .getAddtionItems(params)
      .then((res) => {
        setShowPage(true);
        console.log('res', res);
        store.dispatch(actions.setAdditionItems(res.rows));
        //目前沒有分頁
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    arrProducts = [];
    currentPage = 1;
    totalPage = 0;
    fetchAdditionItemList();
  }, []);

  return (
    <>
      <HeaderBar
        title="加價購商品"
        subtitle=""
        fixed
        goBack={() => navigate(-1)}
      />

      <Content>
        {showPage ? (
          <div>
            {additionItems.length > 0 && (
              <InfiniteScroll
                ref={resultList}
                dataLength={additionItems.length}
                next={() => fetchAdditionItemList()}
                hasMore={hasMore}
                style={{ height: '100%', overflow: 'unset' }}
                loader={<h6>資料載入中...請稍候</h6>}
              >
                <div className="result-list">
                  {additionItems?.map((product, index) => (
                    <AdditionItem
                      data={product}
                      pageName={pageName}
                      searchWord={searchWord}
                      update={() => {}}
                      goNext={(id) => {}}
                      key={`${index}-${product.id}`}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </div>
        ) : !showPage ? (
          <SearchProductPreview />
        ) : undefined}
      </Content>
    </>
  );
};

export default Addition;
